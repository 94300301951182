<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <b-card title="Rivojlanishlar">
        <b-button class="fl mb-2" to="/development-list"  variant="primary" >   <feather-icon icon="InfoIcon" /> Rivojlanishlar ro'yhati</b-button>
        <b-tabs  vertical
                     nav-wrapper-class="nav">
              <b-tab active>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>O'ZBEK TILIDA</span>
                </template>
                <b-form class="mt-0">
                  <b-row>
                    <b-col md="6">

                      <b-form-group
                          label="Sarlavha Uz"
                          label-for="blog-edit-title"
                          class="mb-2"

                      >
                        <b-form-input
                            id="blog-edit-title"
                            v-model="developmentForm.titleUz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">

                      <b-form-group
                          label="Sarlavha Ru"
                          label-for="blog-edit-title"
                          class="mb-2"

                      >
                        <b-form-input
                            id="blog-edit-title"
                            v-model="developmentForm.titleRu"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">

                      <b-form-group
                          label="Sarlavha ENG"
                          label-for="blog-edit-title"
                          class="mb-2"

                      >
                        <b-form-input
                            id="blog-edit-title"
                            v-model="developmentForm.titleEn"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group
                          label="Yosh kategoriyasi"
                          label-for="Kategoriya"
                          class="mb-2"
                      >
                        <v-select
                            v-model="developmentForm.ageCategoryId"
                            :options="ageCategoryTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Yosh kategoriyasini tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group
                          label="Holati"
                          label-for="blog-edit-category"
                          class="mb-2"
                      >
                        <v-select
                            v-model="developmentForm.isActive"
                            :options="isActiveTypes"
                            label="text"
                            :reduce="option => option.id"
                            placeholder="Tanlang"

                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                          label="Kontent UZ"
                          label-for="blog-content"
                          class="mb-2"
                      >
                        <quill-editor
                            p
                            style="height: 300px;"
                            id="blog-content"
                            placeholder="Ma'lumotni kiriting"
                            v-model="developmentForm.contentUz"
                            :options="snowOption"
                        />

                      </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        class="mb-2 mt-4"
                    >


                      <div class="border rounded p-2">
                        <b-button
                            @click="cancledAdviceForm()"
                            class="mr-2"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                        >
                          Bekor qilish
                        </b-button>
                        <b-button
                            @click="submitAdviceForm()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                        >
                          Saqlash
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <b-tab >
                <template #title>
                  <feather-icon icon="FlagIcon" />
                  <span>RUS TILIDA</span>
                </template>

                <b-form class="">
                  <b-row>

                    <b-col cols="12">
                      <b-form-group
                          label="Kontent RU"
                          label-for="blog-content"
                          class="mb-2"
                      >
                        <quill-editor
                            p
                            style="height: 300px;"
                            id="blog-content"
                            placeholder="Ma'lumotni kiriting"
                            v-model="developmentForm.contentRu"
                            :options="snowOption"
                        />

                      </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        class="mb-2 mt-4"
                    >


                      <div class="border rounded p-2">
                        <b-button
                            @click="cancledAdviceForm()"
                            class="mr-2"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                        >
                          Bekor qilish
                        </b-button>
                        <b-button
                            @click="submitAdviceForm()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                        >
                          Saqlash
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="FlagIcon" />
                  <span>INGILIZ TILIDA</span>
                </template>

                <b-form class="">
                  <b-row>

                    <b-col cols="12">
                      <b-form-group
                          label="Kontent ENG"
                          label-for="blog-content"
                          class="mb-2"
                      >
                        <quill-editor
                            p
                            style="height: 300px;"
                            id="blog-content"
                            placeholder="Ma'lumotni kiriting"
                            v-model="developmentForm.contentEn"
                            :options="snowOption"
                        />

                      </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        class="mb-2 mt-4"
                    >


                      <div class="border rounded p-2">
                        <b-button
                            @click="cancledAdviceForm()"
                            class="mr-2"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                        >
                          Bekor qilish
                        </b-button>
                        <b-button
                            @click="submitAdviceForm()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                        >
                          Saqlash
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
            </b-tabs>
          <hr>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import {
  BButton,
  BModal,
  BTable,
  BFormSelect,
  BPagination,
  VBModal,
  BFormCheckbox,
  BRow,
  BCol,
  BForm,
  BCard,
  BFormInput,
  BFormGroup, BMediaBody, BMediaAside, BImg, BMedia, BFormFile, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    quillEditor,
    BAvatar,
    BLink,
    BFormFile,
    BMedia,
    BCardCode,
    BTabs,
    BTab,
    BImg,
    BMediaAside,
    BCardText,
    BMediaBody,
    ValidationProvider,
    BRow,
    BCol,
    vSelect,
    BTable,
    BFormSelect,
    BPagination,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      blogEdit: {},
      blogFile: null,
      categoryOption: ['Fashion', 'Food', 'Gaming', 'Quote', 'Video'],
      statusOption: ['Published', 'Pending'],
      snowOption: {
        theme: 'snow',
        placeholder: 'Ma‘lumotni kiriting',

      },
      editedUserId: null,

      ageCategoryTypes: [
        { id: 1, text: '1 oylik' },
        { id: 2, text: '2 oylik' },
        { id: 3, text: '3-4 oylik' },
        { id: 4, text: '5-6 oylik' },
        { id: 5, text: '7-9 oylik' },
        { id: 6, text: '10-12 oylik' },
        { id: 7, text: '13-18 oylik' },
        { id: 8, text: '19-24 oylik' },
      ],
      isActiveTypes: [
        { id: 1, text: 'Faol' },
        { id: 2, text: 'Nofaol' },
      ],
      category: {
        type: '',
      },
      adviceCategoryTypes: [
        { value: 'UYNASH', text: 'O‘ynash va o‘rganish' },
        { value: 'XAVF', text: 'Xavfsizlik va himoya' },
        { value: 'OTAONA', text: 'Ota-onalar burchagi' },
        { value: 'SALOMATLIK', text: 'Salomatlik va farovonlik' },
        { value: 'SEZGIR', text: 'Sezgir tarbiya va g‘amxo‘rlik' },
        { value: 'OVQAT', text: 'Ovqatlantirish va ko‘krak suti bilan emizish' },
      ],
      adviceForm: {
        "id": null,
        "titleUz": '',
        "titleEn": '',
        "titleRu": '',
        "contentUz": '',
        "contentEn": '',
        "contentRu": '',
        "ageCategoryId": null,
        "type": '',
        "forToday": null,
        "isActive": null,
        "isDeleted": null,
        "hashId": null
      },
      developmentForm: {
        "id": null,
        "titleUz": '',
        "titleEn": '',
        "titleRu": '',
        "contentUz": '',
        "contentEn": '',
        "contentRu": '',
        "ageCategoryId": null,
        "isDeleted": null,
        "isActive": null
      },

      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      users: [],
      items: [],
      tableKey:0,
    }
  },
watch: {

},
  created() {
    this.editedUserId = this.$route.params.id;
    if (this.editedUserId) {
      this.fetchDevelopmentData(this.editedUserId);
    }

    // this.$http.get('/blog/list/data/edit').then(res => { this.blogEdit = res.data })
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
  methods: {
    fetchDevelopmentData(id) {
      this.$http.get(`/rivojlanish/find-by-id/${id}`)
          .then(response => {
            const developmentData = response.data;
            this.developmentForm = {
              id: developmentData.id,
              titleUz: developmentData.titleUz,
              titleRu: developmentData.titleRu,
              titleEn: developmentData.titleEn,
              contentUz: developmentData.contentUz,
              contentRu: developmentData.contentRu,
              contentEn: developmentData.contentEn,
              ageCategoryId: developmentData.ageCategoryId,
              isActive: developmentData.isActive,
            };
          })
          .catch(error => {
            console.error('Axios error:', error);
          });
    },


    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureSuccess(res, file) {
      this.adviceForm.hashId = res.hashId;
    },
    cancledAdviceForm() {
      this.developmentForm = {};
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: 'Rivojlanish',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
          text: "Ma'lumot saqlash bekor qilindi !",
        },
      })
    },
    submitAdviceForm() {
      this.$http
        .post(`/rivojlanish/save`, this.developmentForm)
        .then(() => {
          this.developmentForm={};
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Rivojlanish',
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: "Ma'lumot muvaffaqiyatli saqlandi !",
            },

          })
          this.$router.push({
            name: 'development-list',
          })
          this.$bvModal.hide('modal-login')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Xatolik yuz berdi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: "Ma'lumotni saqlashda xatolik yuz berdi. Qaytadan urinib ko'ring",
            },
          })
        })
    },
    handleMouseOver(event) {
      event.target.classList.add('hovered-icon');
    },
    handleMouseOut(event) {
      event.target.classList.remove('hovered-icon');
    },
  },


}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
.hovered-icon{
  transition: transform 0.25s ease;
  cursor: pointer;
  font-size: 12px;
  color: #7367f0;

}

</style>
